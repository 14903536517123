import React from "react"
import classes from "./about.module.scss"

import Layout from "../components/layout"
import Img from "../components/image"

import SEO from "../components/seo"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

const About = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "title--about" })}
        lang={intl.locale}
      />
      <section className={classes.about}>
        <div className={classes.about__img}>
          <Img alt="zdjecie o nas" filename="about-img.png"></Img>
        </div>
        <div className={classes.about__textbox}>
          <h2
            style={{
              textAlign: "center",
            }}
          >
            <FormattedMessage id="aboutHeading" />
          </h2>
          <p>
            <FormattedMessage id="aboutText" />
          </p>
        </div>
      </section>

      <section className={classes.technologie}>
        <h2>
          <FormattedMessage id="technologiesHeading" />
        </h2>
        <div className={classes.technologie__box}>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/babel-original.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Babel</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/bootstrap-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Bootstrap</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/css3-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Css3</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/git-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Github</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/html5-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Html5</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/illustrator-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Illustrator</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/jquery-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Jquery</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/nodejs-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>NodeJs</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/mysql-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Mysql</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/npm-original-wordmark.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Npm</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/photoshop-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Photoshop</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/php-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Php</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/react-original.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>React</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/redux-original.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Redux</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/sass-original.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Sass</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/webpack-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Webpack</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/wordpress-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Wordoress</p>
          </div>{" "}
        </div>
      </section>
    </Layout>
  )
}

export default About
